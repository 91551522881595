<!-- 关于我们 -->
<template>
  <div>
    <!-- 中文 -->
    <div v-if="language === 'zh_CN'" class="aboutus-info">
      <h1 style="text-align: center">世界大健康经济发展联盟</h1>
      <p>21世纪是大健康的时代，享受健康养生的高品质生活，是人类共同的追求。</p>
      <p>健康无国界，肩负人类健康与发展的使命与梦想，由FOHERB、EA共同倡议发起成立世界大健康经济发展联盟（WHIEDA），为全球大健康产业及优秀企业、行业组织、科研机构、业界专家搭建集聚高端资源的全球性平台，惠泽人类健康。</p>
      <p>WHIEDA聘请中医大师张大宁教授为全球健康推广顾问，张大宁大师是中医肾病学奠基人、国际欧亚科学院院士、权威的中国国家领导人的保健专家，在全球享有盛誉。国际天文联合会将8311号小行星命名为“张大宁星”，成为第一位获此殊荣的当代医学家。</p>
      <p>WHIEDA由联盟主席团领导，下设秘书处、产业经济发展委员会、健康养生教育委员会、产业专家顾问委员会、产业金融服务委员会等专业机构，并在欧亚、非洲、美洲及亚太等地设立区域性分支机构。</p>
      <p>WHIEDA与俄罗斯联邦社会组织健康联盟、联邦退休者联盟、俄罗斯中医药专家学会、国际东方医学学会、亚非妇女发展与权益保障促进会等机构建立了紧密的合作关系，拥有FOHERB、优先健康、雪活泉等众多联盟企业，在为消费者提供健康食品、保健器材、保洁用品、化妆品、小型厨具、家用电器等六大系列优质产品。</p>
      <el-image
          style="width: 25rem; height: 25rem; margin: 0; padding: 0"
          :src="require('@/assets/images/aboutus/tu1.jpg')"
          fit="contain" />
      <p>WHIEDA主席、FOHERB董事长、FOHOWAY （Tianjin）Pharmaceutical Co.,Ltd. 董事长 韩金明先生，是卓越的企业战略家、企业运营专家，是将西方营养学结合中医养生理念成功推向全球的先行者。</p>
      <el-image
          style="width: 25rem; height: 25rem; margin: 0; padding: 0"
          :src="require('@/assets/images/aboutus/tu2.png')"
          fit="contain" />
      <p>WHIEDA执行主席、FOHERB总裁、FOHOWAY （Tianjin）Pharmaceutical Co.,Ltd. 总裁， FOHOWAY 董事长 曲峻生先生，是杰出的大健康行业管理专家，具有丰富的全球化企业运营管理经验。</p>
      <el-image
          style="width: 25rem; height: 25rem; margin: 0; padding: 0"
          :src="require('@/assets/images/aboutus/tu3.png')"
          fit="contain" />
      <p>EA联盟主席、FOHERB副总裁，优先健康公司董事长刘挺先生，具有丰富的跨国公司及多元文化管理经验。</p>
      <el-image
          style="width: 25rem; height: 25rem; margin: 0; padding: 0"
          :src="require('@/assets/images/aboutus/tu4.png')"
          fit="contain" />
      <p>WHIEDA欧亚区副主席、FOHERB西欧区总裁、措吉多吉先生。深耕欧洲健康行业数十载，具有丰富的公司运营经验。</p>
      <p>WHIEDA发起单位——EA是一个横跨欧亚、立足大健康产业的服务平台，致力于发掘欧亚各国大健康产业潜力，提供就业促进发展，提升民众健康幸福指数。</p>
      <p>  WHIEDA发起单位——FOHERB，是一家研发、生产健康养生产品的生物高科技企业，辐射欧洲、亚洲、北美、非洲等国际市场，是世界经济论坛新领军企业、防癌抗癌总裁圆桌会成员单位、中国国家级高新技术企业。</p>
      <p> FOHERB产品通过了中国、美国、英国等多国权威认证，FOHERB生产的虫草系列保健食品十多年以来在全球市场拥有数亿消费群体，其中凤凰口服液获得发明专利证书。</p>
      <p>欢迎全球各界人士，企业、组织及单位加入世界大健康经济发展联盟，成为世界大健康经济发展联盟的一员，您将拥有全球视野，拥抱全球资源，乘中国一带一路的全球发展理念，合作共赢，共铸辉煌。</p>
      <p>让我们共同打造世界上最成功的健康养生事业平台.</p>
    </div>
    <!-- 希伯来语 -->
    <div v-else-if="language === 'he_IL'" class="aboutus-info">
      <h1 style="text-align: center">התאחדות בינלאומית "בריאות ושגשוג"</h1>
      <p>המאה ה- 21 הינה תקופה של בריאות. בריאות איתנה הינה השאיפה העיקרית וערך העליון של כל האנשים. </p>
      <p>בריאות אינה יודעת גבולות. מתוך תחושת שליחות וחלום על התפתחות ובריאות האדם, ביוזמתן של חב' FOHERB ושל התאחדות אירופה-אסיה הוקמה התאחדות בינלאומית "בריאות ושגשוג" WHIEDA - מסד כלל עולמי אשר למען בריאות האדם מאחדת תאגידים עולמיים מצליחים מענף תעשיית הבריאות, משאבים בלעדיים של התעשיה ושל מיזמי המחקר המדעי.  </p>
      <p>לצורך פיתוח תרבות הבריאות הזמינה WHIEDA לתפקיד יועץ את מר צ'ז'אן דנין, פרופסור לרפואה סינית מסורתית. הוא מייסד ענף הנפרולוגיה ברפואה סינית מסורתית, חבר באקדמיית המדעית הבינלאומית של אירופה ואסיה וכן מומחה בעל סמכות בתחום הבריאות מוערך הן על ידי מנהיגי סין והן ע"י קהילה המדעית העולמית. איחוד האסטרונומים הבינלאומי העניק לאסטרואיד 8311 את השם "כוכב צ'ז'אן דנין", ובכך הפך צ'ז'אן דנין למדען הראשון בתחום הבריאות בימינו, שזכה למחוות כבוד זו.</p>
      <p>התאחדות בינלאומית "בריאות ושגשוג" WHIEDA מנוהלת ע"י המועצה, מוסדותיה העיקריים הם המזכירות, ועדת פיתוח תעשיה וכלכלה, ועדת חינוך לתברואה, ועדה מומחים מייעצת והוועדה לתמיכה כספית בייצור. כמו כן, הוקמו סניפים אזוריים באירופה, אסיה, אפריקה, אמריקה ובאזור דרום מזרח אסיה.</p>
      <p>WHIEDA ביססה יחסי שיתוף פעולה הדוקים עם ארגונים שונים, בינהם ארגון רוסי פדראלי ציבורי "ליגת בריאות האומה", איגוד הגמלאים, קהילה רוסית מדעית של מומחים ברפואה סינית מסורתית, קהילה בינלאומית של רפואת המזרח והתאחדות אסיה ואפריקה לתמיכה בהתפתחות זכויות האישה והגנה עליהן. המפעלים הרבים של ההתאחדות, כולל FOHERB, Healfh-Priority, XueHuoquan מציעים לצרכנים 6 סדרות של מוצרים באיכות גבוהה, כולל תוספי תזונה ביולוגיים, ציוד בתחום הבריאות, חומרי ניקוי, מוצרי איפור, כלי מטבח ומכשירי חשמל ביתיים.</p>
      <el-image
          style="width: 25rem; height: 25rem; margin: 0; padding: 0"
          :src="require('@/assets/images/aboutus/tu1.jpg')"
          fit="contain" />
      <p>מר חאן צזינמין - יו"ר התאחדות WHIEDA, יו"ר מועצות המנהלים של FOHERB ו-Fohow （Tianjin）Pharmaceutical  - איש עסקים ואסטרטג עסקי בולט, הוא גם חלוץ שהצליח למזג בין מדעי התזונה המערביים לבין תורת היגיינת הבריאות של רפואה סינית מסורתית, וגם להפיץ אותה ברחבי העולם.</p>
      <el-image
          style="width: 25rem; height: 25rem; margin: 0; padding: 0"
          :src="require('@/assets/images/aboutus/tu2.png')"
          fit="contain" />
      <p>מר צוי צזיונשן - יו"ר מבצע של התאחדות WHIEDA, נשיא FOHERB ו-Fohow （Tianjin）Pharmaceutical, וכן יו"ר מועצת מנהלים של FOHOWAY - אחד ממנהלי העלית בתעשיית הבריאות ובעל ניסיון עשיר בניהול תאגידים בינלאומיים.</p>
      <el-image
          style="width: 25rem; height: 25rem; margin: 0; padding: 0"
          :src="require('@/assets/images/aboutus/tu3.png')"
          fit="contain" />
      <p>מר לו טין - יו"ר התאחדות אירופה ואסיה "בריאות ושגשוג", סגן נשיא תאגיד FOHERB, נשיא אשכול חברות "Healfh-Priority", בעל ניסיון רב שנים של עבודה בחברות רב לאומיות ובניהול רב תרבותי.</p>
      <el-image
          style="width: 25rem; height: 25rem; margin: 0; padding: 0"
          :src="require('@/assets/images/aboutus/tu4.png')"
          fit="contain" />
      <p>מר צודי דודי - סגן יו"ר התאחדות אירופה ואסיה "בריאות ושגשוג", נשיא הסניף מערב אירופה של תאגיד FOHERB, במשך עשרות שנים משתתף פעיל בפעילות תעשיית הבריאות של אירופה ובעל ניסיון עבודה רב בחברה.</p>
      <p>התאחדות אירופה ואסיה "בריאות ושגשוג" יסדה תשתית שירות עבור מפעלים גדולים הפועלים בתחום הבריאות, שואפת לחקור את הפוטנציאל של חברות באירופה ובאסיה, תומכת בהתפתחות ע"י יצירת מקומות עבודה ומשפרת את איכות החיים של האוכלוסיה.</p>
      <p>חב' FOHERB הינה תאגיד ביו טכנולוגי העוסק במחקר, פיתוח וייצור של מוצרי בריאות עבור השווקים של אירופה, אסיה, צפון אמריקה ואפריקה. החברה הינה שותפה בפורום הכלכלי העולמי, חברה בשולחן עגול למלחמה בסרטן, נמנית עם מפעלי הטכנולוגיות הגבוהות והחדשניות ברמת חשיבות מדינית.</p>
      <p>התוצרת של חב' FOHERB קיבלה רישיונות במדינות רבות, כולל בסין, ארה"ב ובריטניה. סדרת תוספי תזונה על בסיס קורדיספס מתוצרת חב' FOHERB נצרכת ע"י מיליוני לקוחות בשוק העולמי כבר מעל 10 שנים. אחד מהם, השיקוי "פוחואו", רשום כפטנט.</p>
      <p>אנחנו מזמינים אנשים, מפעלים וארגונים מכל רחבי העולם להצטרף ולהפוך לחברי ההתאחדות הבינלאומית "בריאות ושגשוג" WHIEDA. ייפתחו בשבילכם אפיקים חדשים, משאבי העולם ואפשרויות של הפרויקט הבינלאומי "רצועה אחת, דרך אחת", שיתוף פעולה משתלם לכל הצדדים והצלחה מובטחת.</p>
      <p>בואו במאמץ משותף ניצור את תשתית העסקים המוצלחת ביותר בעולם!</p>
    </div>
    <!-- 英语 -->
    <div v-else-if="language === 'en_GB'" class="aboutus-info">
      <h1 style="text-align: center">The 21st century is an era of great health. Enjoying a healthy and high-quality life is the common pursuit of mankind.</h1>
      <p>Health knows no borders. For supporting the mission and dream of human health and development, FOHERB and EA jointly initiated to establish the World Health Industry and Economic Development Alliance (WHIEDA), which is a global platform for global health industries or companies, profession organizations, scientific research institutions and professional experts. This global platform will be gathered top resources to benefit human health.</p>
      <p>WHIEDA's global health promotion consultant is Professor Zhang Daning who is the master of traditional Chinese medicine. Master Zhang Daning is the famous expert in the world. He is the founder of nephrology in traditional Chinese medicine, an academician of the International Eurasian Academy of Sciences, and an authoritative health care expert for Chinese national leaders. When the asteroid-8311 was named "Zhang Daning Star" by The International Astronomical Union named,he is the first one of contemporary medical scientist to win this honor.</p>
      <p>WHIEDA is professional organizations which is led by the presidium of the alliance.It was consists of the Secretariat, the Industrial Economic Development Committee, the Health and Health Education Committee, the Industrial Expert Advisory Committee, and the Industrial Financial Services Committee. WHIEDA has established regional branches in Europe, Asia, Africa, the American,the Asia-Pacific and so on.</p>
      <p>WHIEDA has cooperated with many organizations deeply, such as the Russian Federal Social Organization Health Alliance, the Federal Retirees Union, the Russian Society of Traditional Chinese Medicine Experts, the International Society of Oriental Medicine, and the Asian-African Women's Development Association. It has many enterprises allied, such as FOHERB, Priority Health, Xuehuoquan, to provide for six field of high-quality products, including healthy food, health care equipment, cleaning supplies, cosmetics, kitchen utensils and household appliances.</p>
      <el-image
          style="width: 25rem; height: 25rem; margin: 0; padding: 0"
          :src="require('@/assets/images/aboutus/tu1.jpg')"
          fit="contain" />
      <p>Mr. Han Jinming is the Chairman of WHIEDA, the Chairman of FOHERB, the Chairman of FOHOWAY (Tianjin) Pharmaceutical Co., Ltd., is an outstanding business strategist and business operation expert, and as a pioneer who successfully combined and promoted the concept of Western nutrition and traditional Chinese medicine health preservation to the world.</p>
      <el-image
          style="width: 25rem; height: 25rem; margin: 0; padding: 0"
          :src="require('@/assets/images/aboutus/tu2.png')"
          fit="contain" />
      <p> Mr. Qu Junsheng, Executive Chairman of WHIEDA, President of FOHERB, President of FOHOWAY (Tianjin) Pharmaceutical Co., Ltd., Chairman of FOHOWAY, is an outstanding management expert in the health industry, and has rich experience in global enterprise operation and management.</p>
      <el-image
          style="width: 25rem; height: 25rem; margin: 0; padding: 0"
          :src="require('@/assets/images/aboutus/tu3.png')"
          fit="contain" />
      <p>Mr. Liu Ting, chairman of EA Alliance, the vice president of FOHERB, and chairman of Priority Health Company, has rich experience in multinational companies and multicultural management.</p>
      <el-image
          style="width: 25rem; height: 25rem; margin: 0; padding: 0"
          :src="require('@/assets/images/aboutus/tu4.png')"
          fit="contain" />
      <p>Mr. Cuoji Duoji，the Vice Chairman of WHIEDA Eurasia, President of FOHERB Western Europe, has been deeply involved in the European health industry for decades,and has rich experience in company operations.</p>
      <p>One of the WHIEDA initiator,EA ,is a platform that serviced big health industry spans Europe and Asia. It focuses on exploring the potential of the big health industry in Eurasian countries, providing employment and improving the pepole's health and happiness index.</p>
      <p>One of the WHIEDA initiator,FOHERB,is a bio-tech enterprise that researches and develops and produces health care products, radiating to international markets such as Europe, Asia, North America, Africa,and so on. It is a new leading enterprise of the World Economic Forum, a member unit of the anti-cancer and anti-cancer CEO Roundtable, and a national high-tech enterprise in China.</p>
      <p>FOHERB products have the authoritative certification of China, the United States, the United Kingdom and other countries. The Cordyceps series health food supplement produced by FOHERB has attracted hundred of millions of consumers in the global market for more than ten years, and Phoenix Oral Liquid has obtained the invention patent certificate.</p>
      <p>Welcome everyone, enterprises, organizations and units around the world, to join the World Health Industry and Economic Development Alliance，and become a member of WHIEDA. You will have a global vision, embrace global resources, and take opportunity of the concept of China's Belt and Road global development. Cooperate and win together, glory together！</p>
      <p>Let us build the best and successful health care business platform in the world!</p>
    </div>
    <!-- 立陶宛语 -->
    <div v-else-if="language === 'lt_LT'" class="aboutus-info">
      <h1 style="text-align: center">Tarptautinė asociacija «Sveikata ir klestėjimas»</h1>
      <p>XXI a. yra sveikatos era, gera sveikata - didžiausia gyvenimo vertybė ir visų žmonių troškimas.</p>
      <p>Sveikata nežino ribų. Vykdant misiją sijusią su svajonę apie žmogaus sveiką gerbuvį FOHERB ir Eurazijos Asociacijos iniciatyva buvo sukurta tarptautinė Asociacija «Sveikata ir klestėjimas» WHIEDA – pasaulinė platforma, vienijanti geriausias pasaulio sveikatos pramonės korporacijas.</p>
      <p>Siekdama plėtoti sveikatos kultūrą, WHIEDA į savo komandą pakvietė kinų tradicinės medicinos profesorių Zhang Daning buti kompanijos pagrindiniu patarėju medecinai. Zhang Daning - laikomas nefrologijos įkūrėju tradicinėje kinų medicinoje, yra Tarptautinės Eurazijos akademijos mokslininkas ir autoritetingas sveikatos srities ekspertas tarp Kinijos lyderių ir pasaulio mokslo bendruomenės. Tarptautinė astronomų sąjunga asteroidą 8311 pavadino «Zhang Daning žvaigžde». Zhang Daning šio metu yra  pirmas ir venintelis šiuolaikinės medicinos mokslininku, gavusiu šią garbę.</p>
      <p>Tarptautinei sveikatos ir klestėjimo asociacijai WHIEDA vadovauja prezidiumas, jos pagrindinę struktūrą sudaro Sekretoriatas, Pramonės ir ekonominės plėtros Komitetas, Sveikatos mokymo Komitetas, Pramonės ekspertų patariamasis komitetas ir Finansinės paramos ir gamybos komitetas. Taip pat įkūrti regioninei asociacijos filialai Europoje, Azijoje, Afrikoje, Amerikoje ir Azijos-Ramiojo vandenyno regione.</p>
      <p>WHIEDA užmezgė glaudžius bendradarbiavimo ryšius su tokiomis organizacijomis kaip Rusijos federaline visuomenine organizacija «Tautos sveikatos lyga», Pensininkų sąjunga, Rusijos tradicinės kinų medicinos moksline draugija, Tarptautine Rytų medicinos draugija ir Azijos-Afrikos moterų teisių propagavimo ir gynimo asociacija. Daugybė Asociacijei priklausančiu įmonių, įskaitant FOHERB, Health-Priority, XueHuoquan, vartotojams tiekia šešias aukštos kokybės produktų serijas, įskaitant maisto papildus, sveikatos įrangą, valymo priemones, kosmetiką, smulkius virtuvės reikmenis ir buitinius elektros prietaisus.</p>
      <el-image
          style="width: 25rem; height: 25rem; margin: 0; padding: 0"
          :src="require('@/assets/images/aboutus/tu1.jpg')"
          fit="contain" />
      <p>Ponas Han Jinming - WHIEDA asociacijos pirmininkas, FOHERB ir FOHOWAY (Tianjin) Pharmaceutical direktorių valdybos pirmininkas - Han Jinming yra pionierius, sėkmingai sugebėjęs sujungti vakarietišką mitybą ir kinų tradicinės medicinos sveikatos higienos koncepciją, kuri išpopuliarėjo visame pasaulyje.</p>
      <el-image
          style="width: 25rem; height: 25rem; margin: 0; padding: 0"
          :src="require('@/assets/images/aboutus/tu2.png')"
          fit="contain" />
      <p>Ponas Qu Junsheng WHIEDA asociacijos vykdomasis pirmininkas, FOHERB ir FOHOWAY (Tianjin) Pharmaceutical Co., Ltd. prezidentas ir FOHOWAY direktorių valdybos pirmininkas - Qu Junshengas yra puikus sveikatos priežiūros pramonės vadovas ir turi didelę tarptautinių įmonių valdymo patirtį.</p>
      <el-image
          style="width: 25rem; height: 25rem; margin: 0; padding: 0"
          :src="require('@/assets/images/aboutus/tu3.png')"
          fit="contain" />
      <p>Ponas Liu Tingas – Eurazijos sveikatos ir gerovės asociacijos pirmininkas «Sveikata ir klestėjimas» korporacijos FOHERB viceprezidentas, «Health Priority» įmonių grupės prezidentas. Liu Tingas turi ilgametę patirtį tarptautinėse korporacijose ir daugiakultūrėje vadyboje.</p>
      <el-image
          style="width: 25rem; height: 25rem; margin: 0; padding: 0"
          :src="require('@/assets/images/aboutus/tu4.png')"
          fit="contain" />
      <p>Ponas Cuoji Duoji – Eurazijos «Sveikata ir klestėjimas» asociacijos vicepirmininkas, FOHERB korporacijos Vakarų Europos filialo prezidentas. Cuoji Duoji jau dešimtmečius aktyviai dalyvauja Europos sveikatos priežiūros pramonėje ir turi didelę patirtį tokių įmonių vadovavimui.</p>
      <p>WHIEDA sukūrė paslaugų platformą, kur pagrindinis dėmesys skiriamas didelės sveikatos pramonės Eurazijos šalyse potencialo tyrinėjimui, užimtumo užtikrinimui ir tautos sveikatos ir laimės indekso gerinimui.</p>
      <p>FOHERB - biotechnologijų korporacija, užsiimanti sveikatos produktų tyrimais, plėtra ir gamyba Europos, Azijos, Šiaurės Amerikos ir Afrikos rinkoms. Bendrovė yra Pasaulio ekonomikos forumo narė, kovos su vėžiu apskritojo stalo narė, priklauso nacionalinės svarbos naujų ir aukštųjų technologijų įmonėms.</p>
      <p>FOHERB produktai buvo sertifikuoti daugelyje šalių, įskaitant Kiniją, JAV ir JK. Jau daugiau nei dešimtmetį FOHERB kordicepso pagrindu pagamintų maisto papildų serija pasaulinėje rinkoje pritraukia šimtus milijonų vartotojų, tarp jų Eleksyras FOHOWAY  gavo išradimo patentą.</p>
      <p>Kviečiame žmones, įmones ir organizacijas iš viso pasaulio prisijungti prie tarptautinės asociacijos „Sveikata ir klestėjimas“ WHIEDA ir tapti jos nariais. Čia Jums atsivers nauji horizontai, pasaulio resursai ir galimybės prisijungti prie tarptautinio projekto „Viena juosta, vienas kelias“, tai abipusiai naudingas bendradarbiavimas ir garantuota sėkmė.</p>
      <p>Dirbkime kartu, kurdami sėkmingiausią verslo platformą pasaulyje!</p>
    </div>
    <!-- 格鲁吉亚语 -->
    <div v-else-if="language === 'ka_GE'" class="aboutus-info">
      <h1 style="text-align: center"> საერთაშორისო ასოციაცია "ჯანმრთელობა და კეთილდღეობა"</h1>
      <p>21-ე საუკუნე ჯანმრთელობის ეპოქაა, კარგი ჯანმრთელობა ყველა ადამიანის სიცოცხლის უმაღლესი ღირებულება და სურვილია.</p>
      <p> ჯანმრთელობამ საზღვრები არ იცის. ადამიანის განვითარებისა და ჯანმრთელობის მისიისა და ოცნების შემდეგ, FOHERB-ისა და ევრაზიული ასოციაციის ინიციატივით, შეიქმნა ჯანმრთელობისა
        და კეთილდღეობის საერთაშორისო ასოციაცია WHIEDA - გლობალური პლატფორმა, რომელიც აერთიანებს მსოფლიოს წარმატებულ ჯანდაცვის ინდუსტრიის კორპორაციებს, ინდუსტრიისა და
        კვლევითი საწარმოების ექსკლუზიურ რესურსებს. ადამიანის ჯანმრთელობის საკეთილდღეოდ.</p>
      <p>ჯანმრთელობის კულტურის განვითარების მიზნით, WHIEDA-მ  მრჩევლად მიიწვია ჩინური ტრადიციული მედიცინის პროფესორი ჟანგ დანინგი. ის არის ნეფროლოგიის ფუძემდებელი ტრადიციულ ჩინურ მედიცინაში, ევრაზიის საერთაშორისო მეცნიერებათა აკადემიის აკადემიკოსი და ავტორიტეტული ექსპერტი ჯანდაცვის სფეროში ჩინეთის ლიდერებსა და სამეცნიერო მსოფლიო
        საზოგადოებაში. საერთაშორისო ასტრონომიულმა კავშირმა ასტეროიდ 8311 დაასახელა "ჟან დანინის ვარსკვლავი", გახდა პირველი თანამედროვე მედიცინის-მეცნიერი, რომელმაც მიიღო ეს პატივი.
      </p>
      <p>ჯანმრთელობისა და კეთილდღეობის საერთაშორისო ასოციაცია WHIEDA-ს ხელმძღვანელობს პრეზიდიუმი, მის ძირითად სტრუქტურას შედგება სამდივნო, სამრეწველო და ეკონომიკური
        განვითარების კომიტეტი, ჯანმრთელობის განათლების კომიტეტი, დარგის ექსპერტთა საკონსულტაციო კომიტეტი და წარმოების ფინანსური კომიტეტი. ასევე შეიქმნა რეგიონული ფილიალები ევროპაში, აზიაში, აფრიკაში, ამერიკასა და აზია-წყნარი ოკეანის რეგიონში.</p>
      <p>WHIEDA-მ დაამყარა მჭიდრო თანამშრომლობა ისეთ ორგანიზაციებთან, როგორიცაა რუსეთის ფედერალური საზოგადოებრივი ორგანიზაცია "ჯანმრთელობის ეროვნული ლიგა", პენსიონერთა
        კავშირი, ტრადიციული ჩინური მედიცინის სპეციალისტების რუსეთის სამეცნიერო საზოგადოება, აღმოსავლური მედიცინის საერთაშორისო საზოგადოება და აზიურ-აფრიკული ასოციაცია.
        ქალთა უფლებების ხელშეწყობა და დაცვა. ასოციაციის მრავალი საწარმო, მათ შორის FOHERB, Health-Priority, XueHuoquan, მომხმარებელს აწვდის მაღალი ხარისხის პროდუქტების ექვს
        სერიას, მათ შორის დიეტური დანამატები, ჯანმრთელობის აღჭურვილობა, საწმენდი საშუალებები, კოსმეტიკა, პატარა სამზარეულოს ჭურჭელი და საყოფაცხოვრებო ელექტრო ტექნიკა. </p>
      <el-image
          style="width: 25rem; height: 25rem; margin: 0; padding: 0"
          :src="require('@/assets/images/aboutus/tu1.jpg')"
          fit="contain" />
      <p>ბ-ნი ჰან ჯინმინინგი - WHIEDA ასოციაციის თავმჯდომარე, FOHERB-ისა და Fohow （Tianjin）Pharmaceutical-ის დირექტორთა საბჭოს თავმჯდომარე - გამოჩენილი ბიზნეს სტრატეგი და ბიზნესმენი, არის პიონერი, რომელმაც წარმატებით შეძლო დასავლური კვების მეცნიერების
        და ჯანმრთელობის ჰიგიენის კონცეფციის შერწყმა. ჩინური ტრადიციული მედიცინისა და მისი პოპულარიზაცია მთელ მსოფლიოში.</p>
      <el-image
          style="width: 25rem; height: 25rem; margin: 0; padding: 0"
          :src="require('@/assets/images/aboutus/tu2.png')"
          fit="contain" />
      <p>ბატონი Qu Junsheng, WHIEDA ასოციაციის აღმასრულებელი თავმჯდომარე, FOHERB-ისა და Fohow (Tianjin) Pharmaceutical Co.,
        Ltd-ის პრეზიდენტი და FOHOWAY-ის დირექტორთა საბჭოს თავმჯდომარე, არის გამოჩენილი ტოპ მენეჯერი ჯანდაცვის ინდუსტრიაში და აქვს წამყვანი გამოცდილება. საერთაშორისო საწარმოები.</p>
      <el-image
          style="width: 25rem; height: 25rem; margin: 0; padding: 0"
          :src="require('@/assets/images/aboutus/tu3.png')"
          fit="contain" />
      <p>ბ-ნ ლიუ ტინის – ევრაზიული ასოციაციის „ჯანმრთელობა და კეთილდღეობის“ თავმჯდომარეს, კორპორაციის FOHERB-ის ვიცე-პრეზიდენტს, კომპანიათა ჯანმრთელობის პრიორიტეტული ჯგუფის პრეზიდენტს აქვს მრავალწლიანი გამოცდილება ტრანსნაციონალურ კორპორაციებისა და
        მულტიკულტურულ მენეჯმენტში.</p>
      <el-image
          style="width: 25rem; height: 25rem; margin: 0; padding: 0"
          :src="require('@/assets/images/aboutus/tu4.png')"
          fit="contain" />
      <p>ბ-ნი ცოდი დოდი არის ევრაზიის ჯანმრთელობისა და კეთილდღეობის ასოციაციის თავმჯდომარის მოადგილე, კორპორაციის FOHERB-ის დასავლეთ ევროპის ფილიალის პრეზიდენტი,
        ის ათწლეულების განმავლობაში აქტიურად არის ჩართული ევროპის ჯანდაცვის ინდუსტრიაში და აქვს კომპანიაში მუშაობის დიდი გამოცდილება.</p>
      <p>ევრაზიის ჯანმრთელობისა და კეთილდღეობის ასოციაციამ შექმნა მომსახურების პლატფორმა ჯანდაცვის სექტორში მომუშავე მსხვილი საწარმოებისთვის, ცდილობს გამოიკვლიოს ევროპული და აზიური კომპანიების პოტენციალი, ხელს უწყობს განვითარებას სამუშაო ადგილების მიწოდებით და აუმჯობესებს მოსახლეობის ცხოვრების ხარისხს.</p>
      <p>FOHERB არის ბიოტექნოლოგიური კორპორაცია, რომელიც ეწევა ჯანმრთელობის პროდუქტების კვლევას, განვითარებას და წარმოებას ევროპის, აზიის, ჩრდილოეთ ამერიკისა და აფრიკის ბაზრებზე.
        კომპანია არის მსოფლიო ეკონომიკური ფორუმის წევრი, კიბოს შესახებ მრგვალი მაგიდის წევრი და არის ეროვნული მნიშვნელობის ახალი და მაღალი ტექნოლოგიების ერთ-ერთი საწარმო.</p>
      <p>FOHERB-ის პროდუქტები სერტიფიცირებულია ბევრ ქვეყანაში, მათ შორის ჩინეთში, აშშ-ში და დიდ ბრიტანეთში. FOHERB-ის მიერ წარმოებული კორდიცეპსზე დაფუძნებული დიეტური დანამატების სერია ათ წელზე მეტი ხნის განმავლობაში იზიდავს ასობით მილიონ მომხმარებელს მსოფლიო ბაზარზე,
        მათ შორის FOHERB ORAL LIQUIDმა მიიღო გამოგონების პატენტი.</p>
      <p>ჩვენ ვიწვევთ ადამიანებს, ბიზნესს და ორგანიზაციებს მთელი მსოფლიოდან, შეუერთდნენ და გახდნენ ჯანმრთელობისა და კეთილდღეობის საერთაშორისო ასოციაციის WHIEDA-ს წევრი. გაიხსნება ახალი ჰორიზონტები,
        გლობალური რესურსები და შესაძლებლობები საერთაშორისო პროექტის „ერთი სარტყელი, ერთი გზა“, ურთიერთსასარგებლო თანამშრომლობა და გარანტირებული წარმატება.</p>
      <p>მოდით, ერთად ვიმუშაოთ მსოფლიოში ყველაზე წარმატებული ბიზნეს პლატფორმის შესაქმნელად!</p>
    </div>
    <!-- 俄语 -->
    <div v-else class="aboutus-info">
      <h1 style="text-align: center">Международная Ассоциация «Здоровье и Процветание»</h1>
      <p>21 век – это эпоха здоровья, обладать крепким здоровьем это наивысшая жизненная ценность и желание всех людей.</p>
      <p>Здоровье не знает границ. Следуя миссии и мечте о развитии и здоровье человека, по инициативе компании FOHERB и Евразийской Ассоциации была создана Международная Ассоциация «Здоровье и Процветание» WHIEDA – глобальная платформа, во благо здоровья человека объединяющая мировые успешные корпорации индустрии здоровья, эксклюзивные ресурсы отраслевых и научно-исследовательских предприятий.</p>
      <p>Для развития культуры здоровья WHIEDA пригласила в качестве советника профессора китайской традиционной медицины – Чжан Данина. Он является основоположником нефрологии в традиционной китайской медицине, академиком Международной Евразийской академии наук и авторитетным экспертом в области здравоохранения среди руководящих лиц Китая и в научном мировом сообществе. Международный астрономический союз назвал астероид 8311 «Звездой Чжан Данина», став первым современным ученым-медиком, удостоенным этой чести.</p>
      <p>Международную Ассоциацию «Здоровье и Процветание» WHIEDA возглавляет Президиум, ее основная структура состоит из Секретариата, Комитета по промышленно-экономическому развитию, Комитета по санитарному просвещению, Консультативного комитета отраслевых экспертов и Комитета по финансовому обеспечению производства. Также учреждены региональные филиалы в Европе, Азии, Африке, Америке и Азиатско-Тихоокеанском регионе.</p>
      <p>WHIEDA установила тесные отношения сотрудничества с такими организациями, как Российская федеральная общественная организация «Лига Здоровья Нации», Союз пенсионеров, Российское научное сообщество специалистов традиционной китайской медицины, Международное сообщество восточной медицины и Азиатско-африканская ассоциация содействия развитию и защите прав женщин. Многочисленные предприятия Ассоциации, среди которых FOHERB, Health-Priority, XueHuoquan предоставляют потребителям шесть серий высококачественных продуктов, включая бады, оздоровительное оборудование, чистящие средства, косметику, мелкую кухонную утварь и бытовые электроприборы.</p>
      <el-image
          style="width: 25rem; height: 25rem; margin: 0; padding: 0"
          :src="require('@/assets/images/aboutus/tu1.jpg')"
          fit="contain" />
      <p>Господин Хань Цзиньмин - Председатель Ассоциации WHIEDA, Председатель совета директоров FOHERB и FOHOWAY （Tianjin）Pharmaceutical  - выдающийся бизнес-стратег и бизнесмен, является пионером, который успешно смог объединить западную нутрициологию и концепцию гигиены здоровья китайской традиционной медицины, а также популяризировать во всем мире.</p>
      <el-image
          style="width: 25rem; height: 25rem; margin: 0; padding: 0"
          :src="require('@/assets/images/aboutus/tu2.png')"
          fit="contain" />
      <p>Господин Цюй Цзюньшэн - Исполнительный председатель Ассоциации WHIEDA, Президент FOHERB и FOHOWAY （Tianjin）Pharmaceutical Co.,Ltd, а также Председатель совета директоров FOHOWAY –  является выдающимся топ менеджером в  индустрии здравоохранения и имеет богатый опыт руководства международными предприятиями.</p>
      <el-image
          style="width: 25rem; height: 25rem; margin: 0; padding: 0"
          :src="require('@/assets/images/aboutus/tu3.png')"
          fit="contain" />
      <p>Господин Лю Тин – Председатель Евразийской Ассоциации «Здоровье и Процветание», Вице-президент корпорации FOHERB, Президент группы компаний «Health Priority» имеет многолетний опыт работы в транснациональных корпорациях и мультикультурном управлении.</p>
      <el-image
          style="width: 25rem; height: 25rem; margin: 0; padding: 0"
          :src="require('@/assets/images/aboutus/tu4.png')"
          fit="contain" />
      <p>Господин Цоди Доди – вице-председатель Евразийской Ассоциации «Здоровье и Процветание», Президент Западноевропейского отделения корпорации FOHERB, Он активно участвует в европейской индустрии здравоохранения на протяжении десятилетий и имеет богатый опыт работы в компании.</p>
      <p>Евразийская Ассоциация «Здоровье и Процветание» создала сервисную платформу для крупных предприятий, работающих в сфере здоровья, стремится исследовать потенциал Европейских и Азиатских компаний, содействует развитию, предоставляя рабочие места, и улучшает качество жизни населения.</p>
      <p>Компания FOHERB это биотехнологическая корпорация, занимающаяся исследованиями, разработками и производством оздоровительной продукции для рынка Европы, Азии, Северной Америки и Африки. Компания является участником Всемирного экономического форума, членом Круглого стола по борьбе с раком, относится к предприятиям новых и высоких технологий государственного значения.</p>
      <p>Продукция FOHERB прошла сертификацию во многих странах, среди которых Китай, США и Великобритания. Серия бадов на основе кордицепса производства FOHERB уже более десяти лет привлекает сотни миллионов потребителей на мировом рынке, среди них Эликсир Фохоу получил патент на изобретение.</p>
      <p>Приглашаем людей, предприятия и организации с разных концов мира присоединиться и стать членом Международной Ассоциации «Здоровье и Процветание» WHIEDA. Для Вас откроются новые горизонты, мировые ресурсы и возможности международного проекта «Один пояс, один путь», взаимовыгодное сотрудничество и гарантированный успех.</p>
      <p> Давайте совместными усилиями создадим самую успешную в мире бизнес-платформу!</p>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'AboutUs',
  computed:{
    ...mapGetters([
      'language'
    ])
  }
}
</script>

<style lang="scss" scoped>
.aboutus-info {
  width: 650px;
  margin:auto;
  text-align: justify;
  text-indent: 2em;
  p {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
@media screen and (max-width: 991px){
  .aboutus-info {
    width: 100%;
    margin:auto;
    text-align: justify;
  }
}
</style>
